<template>
  <v-col
    cols="12"
    md="12"
  >
    <base-material-card
      color="warning"
      class="px-5 py-3"
    >
      <template v-slot:heading>
        <div class="text-h3 font-weight-light">Звонки</div>

        <div class="text-subtitle-1 font-weight-light">
          <h5>Общее количество: {{ callList.length }}</h5>
        </div>
      </template>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="callList"
          :loading="loading"
        >
          <template v-slot:[`item.start_time`]="{ item }">
            <span @click="getItem(item)">
              {{ dateFormatter(item.start_time) }}
            </span>
          </template>
          <template v-slot:[`item.num_a`]="{ item }">
            <span @click="getItem(item)">
              {{ item.num_a }}
            </span>
          </template>
          <template v-slot:[`item.answer_seconds`]="{ item }">
            <span @click="getItem(item)">
              {{ item.answer_seconds }}
            </span>
          </template>
          <template v-slot:[`item.audio_record_link`]="{ item }">
            <span
              @click="getItem(item)"
              style="cursor: pointer"
            >
              {{ item.audio_record_link }}
            </span>
          </template>
        </v-data-table>
      </v-card-text>
    </base-material-card>
    <div class="audio-date__wrapper">
      <div v-if="item !== ''">
        <audio
          controls
          :key="url"
        >
          <source
            :src="url"
            type="audio/mpeg"
          />
        </audio>
      </div>
      <div class="date__wrapper">
        <div class="date__item">
          <h4>От</h4>
          <input
            @change="sortedCalls"
            id="datePicker"
            type="date"
            min="2022-09-06"
            v-model="fromDate"
            :max="maxDate"
          />
        </div>
        <div class="date__item">
          <h4>До</h4>
          <input
            @change="sortedCalls"
            id="datePicker"
            type="date"
            min="2022-09-06"
            :max="maxDate"
            v-model="toDate"
          />
        </div>
      </div>
    </div>
    <div
      v-if="item !== ''"
      class="comment__wrapper"
    >
      <textarea
        style="
          padding: 10px;
          border: 1px solid black;
          width: 100%;
          max-width: 700px;
          margin: 30px auto;
        "
        v-model="comment.text"
        name=""
        id=""
        cols="12"
        rows="4"
      ></textarea>
      <div class="comment__btns">
        <button
          style="
            border: 1px solid black;
            width: 200px;
            padding: 5px;
            border-radius: 10px;
            font-family: sans-serif;
            background-color: black;
            color: white;
            margin: 0 auto;
          "
          @click="sendComment()"
        >
          Сохранить комментарий
        </button>
        <button
          style="
            font-family: sans-serif;
            border: 1px solid black;
            padding: 5px;
            border-radius: 10px;
            width: 200px;
            background-color: #fb8c00;
            color: white;
            margin: 0 auto;
          "
          @click="deleteComment(item)"
        >
          Удалить комментарий
        </button>
      </div>
    </div>
  </v-col>
</template>

<script>
import UserService from '@/app/Services/UserService'
import TableDialogMixin from '@/app/Helpers/mixins/BaseTableDialog'
import dateFormatter from '@/app/Helpers/dateFormatter'
import GetClients from '@/app/Helpers/mixins/essences/getClients'
import GetGoods from '@/app/Helpers/mixins/essences/getGoods'
import GetUsers from '@/app/Helpers/mixins/essences/getUsers'

export default {
  name: 'calls',

  mixins: [TableDialogMixin, GetClients, GetGoods, GetUsers],
  data() {
    return {
      isExistingComment: false,
      comment: {
        call_session_id: '',
        text: '',
        id: '',
      },
      toDate: '',
      fromDate: '',
      maxDate: '',
      url: '',
      item: '',
      dialogCourier: false,
      courierData: {},
      ordersList: [],
      headers: [
        {
          sortable: true,
          text: 'Дата звонка',
          value: 'start_time',
        },
        {
          sortable: false,
          text: 'Номер абонента',
          value: 'num_a',
          align: 'left',
        },
        {
          text: 'Длительность звонка',
          value: 'answer_seconds',
          align: 'left',
        },
        {
          text: 'Ссылка на запись',
          value: 'audio_record_link',
          align: 'left',
        },
      ],
      dialogData: {
        city_id: null,
        client_id: null,
        point_id: null,
        payment_type: null,
        delivery_method: null,
        status: null,
        courier_id: null,
        cost: null,
        delivery_cost: null,
        check_number: null,
        items_id: null,
      },
      callList: [],
    }
  },

  computed: {
    citiesList() {
      return this.$store.state.references.cities
    },

    pointsList() {
      return this.$store.state.references.points
    },

    paymentTypesList() {
      return this.$store.state.references.paymentTypes
    },

    deliveryMethodsList() {
      return this.$store.state.references.deliveryMethods
    },

    orderStatusesList() {
      return this.$store.state.references.orderStatuses
    },

    couriersList() {
      return this.$store.getters['user/couriers']
    },
  },

  async created() {
    this.loading = true
    await this.getCalls()
    this.loading = false

    // await this.getClients()
    // await this.getGoods()
    // await this.getUsers()
  },
  mounted() {
    Date.prototype.toDateInputValue = function () {
      var local = new Date(this)
      local.setMinutes(this.getMinutes() - this.getTimezoneOffset())
      return local.toJSON().slice(0, 10)
    }
    this.maxDate = new Date().toDateInputValue()
  },
  methods: {
    async deleteComment(e) {
      if (this.isExistingComment === true) {
        const response = await UserService.deleteComment(e.comment.id)
        if (response.status) {
          alert('Комментарий удален ')
          location.reload()
        }
      } else {
        alert('Комментарий отсутствует')
      }
    },
    async sendComment() {
      if (this.isExistingComment === false) {
        const response = await UserService.createComment(this.comment)
        if (response.status) {
          alert('Комментарий сохранен')
          location.reload()
        }
      } else {
        const editComment = {
          text: this.comment.text,
        }
        const response = await UserService.editComment(this.comment.id, editComment)
        if (response.status) {
          alert('Комментарий изменен ')
          location.reload()
        }
      }
    },
    async sortedCalls() {
      if (this.fromDate === '') {
        const response = await UserService.getSortedCalls('starttime_to=' + this.toDate)

        if (response.status) {
          this.callList = response.data.cdrlist
        }
      }
      if (this.toDate === '') {
        const response = await UserService.getSortedCalls('starttime_from=' + this.fromDate)

        if (response.status) {
          this.callList = response.data.cdrlist
        }
      } else {
        const response = await UserService.getSortedCalls(
          'starttime_from=' + this.fromDate + `&` + 'starttime_to=' + this.toDate,
        )
        if (response.status) {
          this.callList = response.data.cdrlist
        }
      }
      if (this.callList.length < 1) {
        this.comment.call_session_id = ''
        this.comment.text = ''
        this.item = ''
      }
    },
    getItem(e) {
      this.url = e.audio_record_link
      this.item = e
      this.comment.call_session_id = e.session_id
      this.comment.text = e.comment?.text
      this.comment.id = e.comment?.id
      if (e.comment !== null) {
        this.isExistingComment = true
      }
    },
    dateFormatter,
    async getCalls() {
      const response = await UserService.getCalls()

      if (response.status) {
        this.callList = response.data.cdrlist
      }
    },
  },
}
</script>
<style scoped>
.comment__btns {
  display: flex;
  width: 500px;
  margin: 0 auto;
  justify-content: space-between;
}
.comment__wrapper {
  display: flex;
  flex-direction: column;
}
.audio-date__wrapper {
  display: flex;
}
.date__wrapper {
  display: flex;
  justify-content: space-between;
  width: 266px;
}
.audio-date__wrapper {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
}
</style>
